import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ActionPlanStatusDTO } from '../dto/action-plan-status.dto';

@Injectable({
    providedIn: 'root',
})
export class ActionPlanStatusService {
    private apiUrl = environment.apiUrl; // Use the apiUrl from environment

    constructor(private http: HttpClient) {}

    list(
        sortField: string = 'title_en',
        sortOrder: string = 'asc',
        searchQuery?: string
    ): Observable<ActionPlanStatusDTO[]> {
        let params = new HttpParams()
        .set('sort_by', sortField)
        .set('sort_order', sortOrder);

        if (searchQuery) {
        params = params.set('keyword', searchQuery);
        }

        return this.http.get<ActionPlanStatusDTO[]>(this.apiUrl + '/action-plan-statuses', {
        params,
        });
    }

    getActionPlanStatus(id: number): Observable<ActionPlanStatusDTO> {
        return this.http.get<ActionPlanStatusDTO>(`${this.apiUrl}/action-plan-statuses/${id}`);
    }
  
}
